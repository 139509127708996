<template>
  <page-skeleton theme="light">
    <section-half-image
        arrow
        scroll-to="central-overview-arrow"
        hero
        photo-right-align
        small-photo
        img-background="solid"
        :alt="$t('challenges.overview.hero.alt')"
        image-path="https://ik.imagekit.io/isotronic/challenges/central/dashboard_jX4jdu9VXX.png?updatedAt=1635403454761"
    >
      <template #header>
        {{ $t('challenges.overview.hero.header') }}
      </template>
      <template #content>
        <div class="mb-3 i-font-text i-secondary">
          {{ $t('challenges.overview.hero.text') }}
        </div>
      </template>
    </section-half-image>
    <section-half-image
        id="central-overview-arrow"
        background="light"
        image-path="https://ik.imagekit.io/isotronic/challenges/central/flanged_vials_h6CZ-qmhud.jpg?updatedAt=1635403506427"
        :alt="$t('challenges.overview.section1.alt')"
    >
      <template #header>
        {{$t('challenges.overview.section1.header')}}
      </template>
      <template #content>
        <i18n path="challenges.overview.section1.text" tag="p">
          <template v-slot:space>
            <div class="my-3"></div>
          </template>
        </i18n>
      </template>
    </section-half-image>
    <section-half-image
        photo-right-align
        image-path="https://ik.imagekit.io/isotronic/challenges/central/bernard-hermant_3wmpuX4FDq.jpg?updatedAt=1635403553109"
        :alt="$t('challenges.overview.section2.alt')"
    >
      <template #header>
        {{ $t('challenges.overview.section2.header') }}
      </template>
      <template #content>
        <div>
          {{ $t('challenges.overview.section2.text') }}
        </div>
        <ul class="mt-3 mb-0">
          <li>
            <i18n path="challenges.overview.section2.feature1.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.overview.section2.feature1.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.overview.section2.feature2.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.overview.section2.feature2.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="challenges.overview.section2.feature3.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('challenges.overview.section2.feature3.style') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
      </template>
    </section-half-image>
    <features />
    <div class="img-wrapper i-border w-100">
      <div class="img-container">
        <b-img-lazy
            src="https://ik.imagekit.io/isotronic/challenges/central/central_cosmetic_page_WJ8O5wLnwZ.png?updatedAt=1635403604737"
            :alt="$t('challenges.overview.fullImg.alt1')"
            class="small-img mx-auto"
        />
      </div>
    </div>
    <section-half-image
        background="light"
        small-photo
        img-background="grey"
        image-path="https://ik.imagekit.io/isotronic/challenges/central/production_statistics_90eHGbTVHL.png?updatedAt=1635403645801"
        :alt="$t('challenges.overview.section4.alt')"
    >
      <template #header>
        {{$t('challenges.overview.section4.header')}}
      </template>
      <template #content>
        <i18n path="challenges.overview.section4.text" tag="p">
          <template v-slot:space>
            <div class="my-3"></div>
          </template>
        </i18n>
      </template>
    </section-half-image>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import Features from "@/components/industryChallenges/centralOverview/CentralFeatures";

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    Features
  },
  metaInfo() {
    return {
      title: this.$t('challenges.overview.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('challenges.overview.meta.description')}
      ]
    }
  },
}
</script>

<style lang="sass" scoped>
.small-img
  max-height: inherit !important
  max-width: 100% !important
  display: block
  transform: translateY(-50%)
  padding: 15px
  @media screen and (min-width: $sm)
    //max-height: 510px
    padding: 30px

.img-container
  max-height: inherit
  height: inherit
  transform: translateY(50%)

.img-wrapper
  max-height: 975px
  background: #F19595
  @media screen and (min-width: $sm)
    max-height: 1235px
  @media screen and (min-width: $md)
    max-height: 700px
  @media screen and (min-width: $lg)
    max-height: 750px
  @media screen and (min-width: $xl)
    max-height: 870px

</style>
